<template>
  <v-container>
    <!-- page header -->
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="8">
            <h3 class="display-1">User Accounts</h3>
            <p class="mt-2">Total User Accounts: {{ userAccounts.length }}</p>
          </v-col>
          <v-col cols="12" md="4" align="end">
            <v-btn color="primary" depressed to="/">Home</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- userAccounts table -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="7"></v-col>
              <v-col cols="8" md="5">
                <v-text-field
                  label="Search Users"
                  dense
                  clearable
                  outlined
                  hide-details
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            dense
            :headers="[
              { text: 'Last Name', value: 'lastName' },
              { text: 'Email', value: 'email' },
              { text: 'PUID', value: 'packageID' },
              { text: 'Date Registered', value: 'dateRegistered' },
            ]"
            :items="userAccounts"
            :items-per-page="-1"
            :loading="loading"
            :search="search"
            @click:row="onViewUser"
          >
            <!-- <template v-slot:[`item.name`]="{ item }">
              {{ item.firstName }} {{ item.lastName }}
            </template> -->
            <template v-slot:[`item.dateSubmitted`]="{ item }">
              {{ formatDate(item.dateRegistered) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { portalsApp } from "@/firebase";
import formatDate from "@/mixins/formatDate";
const userAccounts = portalsApp
  .firestore()
  .collection("userAccounts")
  .orderBy("dateRegistered", "desc");
export default {
  mixins: [formatDate],

  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind("userAccounts", userAccounts);
    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    search: "",
    userAccounts: [],
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    onViewUser(user) {
      this.$router.push(`users/${user.id}`);
    },
  },
};
</script>

<style>
tr {
  cursor: pointer !important;
}
</style>
